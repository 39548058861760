<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot />
    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
        :class="{ 'expanded-search': shallShowFullSearch }"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ mdiMenu }}
          </v-icon>
        </div>
        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <!-- <app-bar-i18n /> -->
          <!-- <app-bar-theme-switcher class="mx-4" /> -->
          <!-- <app-bar-notification /> -->
          <app-bar-user-menu />
        </div>
      </div>
    </template>
    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }}
          <a
            href="https://aliaddo.com"
            class="text-decoration-none"
            target="_blank"
          >Aliaddo SAS</a><span class="d-none d-md-inline">, Todos los derechos reservados</span></span>
        <div class="align-center d-none d-md-flex">
          <span>Hecho a mano &amp; Hecho con</span>
          <v-icon
            color="error"
            class="ms-2"
          >
            {{ mdiHeartOutline }}
          </v-icon>
        </div>
      </div>
    </template>
    <template #v-app-content>
      <!-- <app-customizer class="d-none d-md-block" /> -->
    </template>
  </layout-content-vertical-nav>
</template>
<script setup>
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// App Bar Components
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import sharedStoreModule, { STORE_SHARED_MODULE_NAME } from '@/components/sharedStoreModule'
import { getGravatar } from '@/filters'
import menuItems from '@/navigation/vertical'
import { ability } from '@config/ability'
// import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
// import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'
// import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'
import { getVuetify } from '@core/utils'
import { mdiHeartOutline, mdiMenu } from '@mdi/js'
import store, { registerModule } from '@store'
import { onMounted, ref, watch } from 'vue'

registerModule(STORE_SHARED_MODULE_NAME, sharedStoreModule)

const $vuetify = getVuetify()
// Search
const appBarSearchQuery = ref('')
const shallShowFullSearch = ref(false)
const navMenuItems = ref([])
const maxItemsInGroup = 5
const totalItemsInGroup = ref({
  pages: 0,
  files: 0,
  contacts: 0
})
watch(appBarSearchQuery, () => {
  totalItemsInGroup.value = {
    pages: 0,
    files: 0,
    contacts: 0
  }
})
const searchFilterFunc = (item, queryText, itemText) => {
  if (item.header || item.divider) return true
  const itemGroup = (() => {
    if (item.to !== undefined) return 'pages'
    if (item.size !== undefined) return 'files'
    if (item.email !== undefined) return 'contacts'
    return null
  })()
  const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
  if (isMatched) {
    if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
    else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
    else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
  }
  return (
    appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
  )
}
// ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
  if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
    toggleVerticalNavMenuActive()
  }
}

onMounted(() => {
  // ability.can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES)
  for (let index = 0; index < menuItems.length; index++) {
    const menu = menuItems[index]
    if (!menu.subject || !menu.action || ability.can(menu.action, menu.subject)) {
      navMenuItems.value.push(menu)
    }
  }

  store.dispatch(`${STORE_SHARED_MODULE_NAME}/fetchSellers`).then(response => {
    if (response.data.length > 0) {
      const data = response.data.map(item => {
        return {
          ...item,
          avatar: getGravatar(item.email)
        }
      })
      store.dispatch('user/setSellers', data)
    } else {
      store.dispatch('user/setSellers', [])
    }
  })
})
</script>
<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }
    > .d-flex > button.v-icon {
      display: none;
    }
    // ===
    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }
    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
<style lang="scss" scoped>
@import '@core/preset/preset/overrides.scss';
@import '@core/preset/preset/skins/bordered.scss';
@import '@core/preset/preset/skins/semi-dark.scss';
</style>

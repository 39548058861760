import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import { camelCase } from 'lodash'
import { computed } from 'vue'
import { createStore } from 'vuex'
import app from './app'
import user from './modules/users'

const store = createStore({
  modules: {
    appConfig: appConfigStoreModule,
    app,
    user
  }
})

export default store
export function useStore () {
  return store
}
export function registerModule (moduleName, module) {
  if (!store.hasModule(moduleName)) {
    store.registerModule(moduleName, module)
  }
}

export function unRegisterModule (moduleName) {
  if (store.hasModule(moduleName)) {
    store.unregisterModule(moduleName)
  }
}
const mapState = () => Object.fromEntries(
  Object.keys(store.state).map(
    key => [key, computed(() => store.state[key])]
  )
)

const mapGetters = getters => Object.fromEntries(
  getters.map(
    getter => [camelCase(getter), computed(() => store.getters[getter])]
  )
)

// Object.fromEntries(
//   getters.map(getter=>{
//     return computed(() => store.getters[getter])
//   })
//   Object.keys(store.getters).map(
//     getter => [getter, ],
//   ),
// )

// const mapMutations = () => Object.fromEntries(
//   Object.keys(store._mutations).map(
//     mutation => [mutation, value => store.commit(mutation, value)],
//   ),
// )

// const mapActions = () => Object.fromEntries(
//   Object.keys(store._actions).map(
//     action => [action, value => store.dispatch(action, value)],
//   ),
// )

export {

  // mapActions,
  // mapMutations,
  mapGetters,
  mapState
}
